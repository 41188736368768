// @import '~react-flags-select/css/react-flags-select.css';

.flag-select {
  min-width: 85px;
  min-height: 50px;
  margin: 0;
  padding: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  border-right: 1px solid #f6f6f6;
  background-color: white;

  & .country-flag {
    margin-right: 15px;
  }

  & .selected--flag--option {
    width: 100%;
    padding-top: 6px;
    display: flex;
    min-height: 50px;
    font-size: 20px;
    box-sizing: border-box;
    justify-content: center;

    &:focus {
      border: 1px solid #1fb841;
    }
  }

  & .flag-options {
    left: 0;
    top: 50px;

    & > .flag-option.has-label {
      padding: 0px 8px 3px 8px;
    }
  }
}

.flag-select__btn {
  position: relative;

  &:before {
    cursor: pointer;
  }
}
