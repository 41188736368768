.StripeControl {
  height: 50px;
  padding: 15px 20px;
  border: 1px solid #dcdcdf;

  background-color: #fff;

  box-sizing: border-box;
}

.StripeControl.StripeElement--focus {
  border-color: #1fb841;
}

.StripeElement--focus {
}

.StripeElement.IdealBankElement,
.StripeElement.FpxBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.StripeElement.PaymentRequestButton {
  height: 40px;
}
