.modal-open {
  overflow: hidden;
}

.ReactModalPortal {
  height: 0px;
  maxheight: 0px;
  overflow: hidden;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
  z-index: 2147483647;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.fullscreen {
  &.ReactModal__Overlay.ReactModal__Overlay--after-open {
    padding: 0px;
  }

  &.ReactModal__Content.ReactModal__Content--after-open {
    width: 100%;
    height: 100%;
    max-height: 100%;
  }
}

@media only screen and (max-width: $mobile) {
  .mobile-fullscreen {
    &.ReactModal__Overlay.ReactModal__Overlay--after-open {
      padding: 0px;
    }

    &.ReactModal__Content.ReactModal__Content--after-open {
      width: 100%;
      height: 100%;
      max-height: 100%;
    }
  }
}
.overlay {
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(40, 42, 49, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 20px;
}
