body {
  font-family: 'Catamaran', sans-serif;
  margin: 0;
  overflow-x: hidden;

  &.ReactModal__Body--open {
    #root {
      overflow: hidden;
    }
  }
}
