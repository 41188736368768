// basic styles for horizontal slider
.slider {
  position: relative;
  width: 100%;
  height: 400px;
  overflow: hidden;

  & a {
    &.previousButton,
    &.nextButton {
      font-size: 22px;
      line-height: 0;
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      transition: all 0.3s linear;
      z-index: 1;
      color: #333;
      padding: 10px;
      text-decoration: none;
      backface-visibility: hidden; /* prevent jump effect when scaling */

      &:not(.disabled):hover {
        transform: translateY(-50%) scale(1.25);
        cursor: pointer;
      }
    }

    &.previousButton {
      left: 20px;
    }

    &.nextButton {
      right: 20px;
    }
  }
}

.slide {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;

  &.hidden {
    visibility: hidden;
  }

  &.previous {
    left: -100%;
  }

  &.current {
    left: 0;
  }

  &.next {
    left: 100%;
  }

  &.animateIn,
  &.animateOut {
    transition: all 2s ease;
  }

  &.animateIn {
    &.previous,
    &.next {
      left: 0;
      visibility: visible;
    }
  }

  &.animateOut {
    &.previous {
      left: 100%;
    }

    &.next {
      left: -100%;
    }
  }
}

// overrides
.flow-slider {
  position: relative;
  min-width: 434px;
  min-height: 744px;
  overflow: hidden;

  @media (max-width: 1024px) {
    min-width: 375px;
    min-height: 621px;
  }

  @media (max-width: 767px) {
    min-width: 375px;
    min-height: 621px;
    margin-left: auto;
    margin-right: auto;
  }

  & .slide {
    opacity: 0;
  }

  & .slide.animateIn.previous,
  & .slide.current,
  & .slide.animateIn.next {
    opacity: 1;
  }

  &:not(.fade-away) {
    & .slide {
      transition: all 0.9s ease;
    }
  }

  &.fade-away {
    & .slide {
      transition: opacity 0.9s ease;
    }

    & .slide.previous,
    & .slide.hidden,
    & .slide.next {
      left: 0 !important;
      opacity: 0;
    }

    & .slide.current {
      left: 0 !important;
      opacity: 1;
    }

    & .slide.animateIn,
    & .slide.animateOut {
      -webkit-transition: all 0.6s ease;
      transition: all 0.6s ease;
    }

    & .slide.animateIn.previous,
    & .slide.animateIn.next {
      left: 0 !important;
      visibility: visible;
    }

    & .slide.animateOut.previous,
    & .slide.animateOut.next {
      left: 0 !important;
      opacity: 0;
    }
  }
}

.slider-wrapper {
  position: relative;
  height: 400px;
  overflow: hidden;
  margin-top: 40px;

  @media (max-width: 600px) {
    height: calc((600px - 100vw) / 1.6 + 400px);
  }

  & .slide img,
  & .slide .slider-author,
  & .slide .slider-text {
    transition: all 0.3s ease;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 0;
  }

  & .slide * {
    transition: all 0.3s ease;
  }

  & .slide.animateIn.previous .slider-text,
  & .slide.current .slider-text,
  & .slide.animateIn.next .slider-text,
  & .slide.animateIn.previous .slider-author,
  & .slide.current .slider-author,
  & .slide.animateIn.next .slider-author,
  & .slide.animateIn.previous img,
  & .slide.current img,
  & .slide.animateIn.next img {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-transition-delay: 0.3s;
    transition-delay: 0.3s;
    opacity: 1;
  }
}

.flow-slider > .previousButton,
.flow-slider > .nextButton,
.slider-wrapper > .previousButton,
.slider-wrapper > .nextButton {
  position: absolute;
  display: none;
  height: 0;
  width: 0;
  top: -1;
  left: -1;
  pointer-events: none;
}

.slider-content {
}
